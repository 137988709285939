import "./home-project-list-components.css";
import VideoListComponent from "../common/VideoListComponent.jsx";
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video'
import "react-modal-video/css/modal-video.css"

const videoData = [
    {
      id: 4,
      title: 'AC Hotel Split by Marriott',
      videoUrl: 'THOkBmkTkio',
      thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative-2.appspot.com/o/ACMarriot-Thumb.webp?alt=media&token=3aa41a0d-e958-47c0-82b7-7f94c0d36a50',
    },
    {
      id: 7,
      title: 'HNK Hajduk Split',
      videoUrl: 'sx4KJXhZRsY',
      thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative-2.appspot.com/o/hajduk-thumb.webp?alt=media&token=ddd9719b-7ab9-4459-a68e-57e1479e688b',
    },
    {
      id: 2,
      title: 'BMW Drift Omiš',
      videoUrl: 'VMQUQ-zvv9Q',
      thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative-2.appspot.com/o/DriftOmis.webp?alt=media&token=e4c3cd5e-fb0e-4cfd-b3f4-dff9cc80ee02',
    },
    {
      id: 8,
      title: 'Villa Helvetia, Merano',
      videoUrl: 'Q0-jb0Nay40',
      thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative-2.appspot.com/o/villa-helvetia-thumb-hd.webp?alt=media&token=45102ad1-8973-4d30-8f07-dee249e36d93',
    },
    {
      id: 1,
      title: 'Skigaarden, Hemsedal',
      videoUrl: 'bDe0EEqzCDo',
      thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative-2.appspot.com/o/skigaarden.webp?alt=media&token=44e7acac-d9b9-41a5-9bbc-419bf02d076d',
    },
    {
      id: 3,
      title: 'Unique Charter',
      videoUrl: 'fSUZ4XKF1vo',
      thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative-2.appspot.com/o/unique-charter-thumb.webp?alt=media&token=b91d54b4-8cf6-4fe6-94fc-d243080d913d',
    }
  ];

const HomeProjectListComponent = (props) => {

    const [selectedVideo, setSelectedVideo] = useState(null);

    const handleVideoClick = (videoData) => {
      setSelectedVideo(videoData);
      document.body.style.overflow = 'hidden';
    };
  
    const closeModal = () => {
      setSelectedVideo(null);
      document.body.style.overflow = 'unset';
    };

    return (
        <div className="home-project-list-component" >
          <h3>highlighted projects</h3>

          <div className="videoHolder">
          <React.Fragment>
            <VideoListComponent videoList={videoData} onVideoClick={handleVideoClick} />
            <ModalVideo channel='youtube' youtube={{ autoplay: 1, mute:1 }} isOpen={selectedVideo !== null} videoId={selectedVideo?.videoUrl ?? ''} onClose={() => closeModal()} />
          </React.Fragment>
          </div>
        </div>
      );
}

export default HomeProjectListComponent;