import './App.css';
import { BrowserRouter, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { useLayoutEffect } from "react";
import NavBarComponent from "./components/navbar/NavBarComponent.jsx";

import { initializeApp } from "firebase/app";

import HomePage from "./pages/HomePage";
import ServicePage from "./pages/ServicePage";
import WorkPage from "./pages/WorkPage";
import PricingPage from "./pages/PricingPage";
import ContactPage from "./pages/ContactPage";
import PrivacyPage from "./pages/PrivacyPage.jsx";

import Modal from './components/common/VideoModal'

const firebaseConfig = {
  apiKey: "AIzaSyB68LYUWnY_3w1ri9S0aA7r7RBFKxTIMTc",
  // authDomain: "nocturno-creative.firebaseapp.com",
  projectId: "nocturno-creative",
  storageBucket: "nocturno-creative.appspot.com",
  messagingSenderId: "486561252800",
  appId: "1:486561252800:web:b65f19084c5aa0c846b425",
  measurementId: "G-43BHGYCQED",
  authDomain: 'nocturno-creative.com'
};

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBarComponent />
        <Wrapper>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/service" element={<ServicePage />} />
            <Route path="/work" element={<WorkPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
      <Modal/>
    </div>
  );
}

export default App;
