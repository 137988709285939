import "./service-drone-list-component.css";

import DroneItem from "./DroneItem";

const droneData = [
  {
    id: 1,
    title: 'Small indoor FPV Drone',
    text: "Meet our small and agile drone, the ideal choice for indoor drone tours, festivals, or any scenario where close proximity to people is crucial. Weighing in at less than 250 grams, this drone can soar in almost any location with the proper permits. Don't be deceived by its compact size, it packs a powerful punch. For this drone we are using latest GoPro naked to fit weight limit.",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative-2.appspot.com/o/cinewhoop-image.webp?alt=media&token=ee24130a-0568-4b87-ba07-8c2cf30f0b71',
  },
  {
    id: 2,
    title: 'Fast Outdoor FPV Drone',
    text: "Introducing our high-performance outdoor and freestyle FPV drone, engineered to reach speeds of up to 180 km/h. It's the perfect choice for effortlessly keeping pace with fast-moving cars or boats while capturing heart-pounding videos. Equipped with the latest GoPro camera, this drone ensures your footage is nothing short of breathtaking.",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative-2.appspot.com/o/nazgul-image.webp?alt=media&token=45880e4e-8ce1-48c3-ac53-cef9462613e6',
  },
  {
    id: 3,
    title: 'Other Custom FPV Drones',
    text: "We specialize in crafting drones that are perfectly aligned with your unique requirements. Whether your project calls for a cinema-grade camera like the BMPCC or a custom-built drone designed to tackle specialized tasks, we've got you covered. Your vision is our mission, and we're dedicated to delivering drones that get the job done right.",
    thumbnailUrl: 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative-2.appspot.com/o/cinelifter-image.webp?alt=media&token=d153a3de-36a6-42fc-bb85-0278e7a47548',
  },
];

const ServiceDroneListComponent = () => {
  return (
    <div className="dron-component">
      <h3>OUR Drones</h3>
      <div className="drone-list">
        {droneData.map((item) => (
          <DroneItem key={item.id} droneData={item} />
        ))}
      </div>
    </div>
  );
};

export default ServiceDroneListComponent;