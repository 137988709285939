import './drone-item.css'

const DroneItem = ({ droneData }) => {
  return (
    <div className="drone-item">
      <img className="image" src={droneData.thumbnailUrl} alt={droneData.title} />
      {/* <div className="overlay">
      </div> */}

      <div className='drone-name'>
        <h3>{droneData.title}</h3>
      </div>

      <div className='drone-text'>
        <p>{droneData.text}</p>
      </div>

    </div>
  );
};

export default DroneItem;