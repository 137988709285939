import FooterComponent from "../components/footer/FooterComponent";
import HomeCenterTextComponent from "../components/home/HomeCenterTextComponent";
import HomeWelcomeComponent from "../components/home/HomeWelcomeComponent";
import HomeProjectListComponent from "../components/home/HomeProjectListComponent";
import HomeTextComponent from "../components/home/HomeTextComponent";
import headerImage from "../images/main-header-image.webp";

const HomePage = () => {
  return (
    <div>
      <HomeWelcomeComponent
      title="FPV Drone Services"
      desc="Professional Aerial Cinematographers" 
      backgroundImage={headerImage}
      />

      <HomeCenterTextComponent 
      title="precision in every flight"
      text="Trust in the hands of certified drone pilots who merge technology with artistry. We've honed our skills to capture the impossible, blending the thrill of flight with the magic of cinema."
      />

      <HomeProjectListComponent />

      <HomeTextComponent/>

      <FooterComponent/>
    </div>
  );
};

export default HomePage;
