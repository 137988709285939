import "./contact-form-component.css";


const HomeCenterTextComponent = (props) => {
    const sendRequest = () => {
        let email = document.getElementById("email").value;
    
        let name = document.getElementById("name").value;
    
        let message = document.getElementById("message").value;
    
        props.validateAndCheckForm(email, name, message);
      };
      
    return (
        <div className="contact-form-component" >
            <div>
                <div className="contact-details" id="enter-details">
                <h3>Enter your details</h3>

                <div className="contact-body">
                    <div className="left">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Your email"
                    />
                    <div className="line" id="input1"></div>

                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="First and last name"
                    />
                    <div className="line"></div>

                    <div className="textarea">
                        <textarea
                            type="text"
                            id="message"
                            name="message"
                            placeholder="Your Message"
                        />
                    </div>
                    
                    <div className="line" id="input1"></div>

                    <button onClick={sendRequest}>Send message</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
      );
}

export default HomeCenterTextComponent;