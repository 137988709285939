import "./video-list-component.css";
import VideoItem from "../common/VideoItem.jsx";

const VideoList = ({ videoList, onVideoClick }) => {
  return (
    <div className="video-list">
      {videoList.map((videoItem) => (
        <VideoItem key={videoItem.id} videoData={videoItem} onClick={onVideoClick} />
      ))}
    </div>
  );
};

export default VideoList;