import Modal from 'react-modal';

const VideoModal = ({ isOpen, selectedVideoData, onRequestClose }) => {
    
    if (!selectedVideoData) {
        return null;
    }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Video Modal"
      className="video-modal"
      ariaHideApp={false}
    >
      <video controls autoPlay>
        <source src={selectedVideoData.videoId} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
};

export default VideoModal;