import "./service-list-components.css";
import ServiceItem from "./ServiceItem";
import React, { useState } from 'react';
import "react-modal-video/css/modal-video.css"

import { isMobile } from "react-device-detect";

const videoData = [
    {
      id: 1,
      title: 'Automotive',
      videoUrl: 'https://ik.imagekit.io/orezhig40p/AutomotiveDrift.mp4?updatedAt=1700818993234',
      thumbnailUrl: 
      isMobile ? 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative.appspot.com/o/showreel-2023-thumb.webp?alt=media&token=13d40a07-6090-4662-8ea6-53ee20538ead'
      : "https://ik.imagekit.io/orezhig40p/AutomotiveMobile.mp4?updatedAt=1695834255097"
    },
    {
      id: 3,
      title: 'Hotels & Real Estate',
      videoUrl: 'https://ik.imagekit.io/orezhig40p/Hotel.mp4?updatedAt=1700033863668',
      thumbnailUrl: 
      isMobile ? 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative.appspot.com/o/unique-charter-thumb.webp?alt=media&token=f6ebab87-2e51-4534-a0cf-d1180291873c'
      : "https://ik.imagekit.io/orezhig40p/RealEstateMobile.mp4?updatedAt=1695834263699"
    },
    {
      id: 6,
      title: 'Action sports',
      videoUrl: 'https://ik.imagekit.io/orezhig40p/ActionSport.mp4?updatedAt=1700034051817',
      thumbnailUrl: 
      isMobile ? 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative.appspot.com/o/prugovo-thumb.webp?alt=media&token=66b2b9a0-9dd5-4b7a-bb48-6b3796979355'
      : "https://ik.imagekit.io/orezhig40p/ActionSportMobile.mp4?updatedAt=1695834258844"
    },
    {
      id: 4,
      title: 'Weddings',
      videoUrl: 'https://ik.imagekit.io/orezhig40p/Weddings.mp4?updatedAt=1695824739604',
      thumbnailUrl: 
      isMobile ? 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative.appspot.com/o/vjencanje-sustipan-thumb.webp?alt=media&token=4a0fa857-e140-433c-927d-b3a9e88e72ff'
      : "https://ik.imagekit.io/orezhig40p/WeddingsMobile.mp4?updatedAt=1695834268287"
    },
    {
      id: 5,
      title: 'Festivals & Events',
      videoUrl: 'https://ik.imagekit.io/orezhig40p/Festival.mp4?updatedAt=1695824739198',
      thumbnailUrl: 
      isMobile ? 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative.appspot.com/o/villa-podstrana-thumb.webp?alt=media&token=cf6ee1af-f4ec-46c7-855e-25647ae7b03e'
      : "https://ik.imagekit.io/orezhig40p/FestivalMobile.mp4?updatedAt=1695834248217"
    },
    {
      id: 2,
      title: 'Boat & Charter',
      videoUrl: 'https://ik.imagekit.io/orezhig40p/Boat.mp4?updatedAt=1695824740088',
      thumbnailUrl: 
      isMobile ? 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative.appspot.com/o/rent-car-thumb.webp?alt=media&token=e97aeae4-f1e9-402c-b01f-7ad2223a3135'
      : "https://ik.imagekit.io/orezhig40p/BoatMobile.mp4?updatedAt=1695834262177"
    },
    {
      id: 7,
      title: 'Nature',
      videoUrl: 'https://ik.imagekit.io/orezhig40p/Nature.mp4?updatedAt=1695824740566',
      thumbnailUrl: 
      isMobile ? 'https://firebasestorage.googleapis.com/v0/b/nocturno-creative.appspot.com/o/prugovo-thumb.webp?alt=media&token=66b2b9a0-9dd5-4b7a-bb48-6b3796979355'
      : "https://ik.imagekit.io/orezhig40p/NatureMobile.mp4?updatedAt=1695834268697"
    }
  ];

const ServiceListComponent = (props) => {

    const [playingIndex, setPlayingIndex] = useState(null);

    const handlePlay = (index) => {
      if (playingIndex !== null) {
        setPlayingIndex(null);
      }

      setPlayingIndex(index);
    };


    return (
        <div className="service-list-component" >
          <div className="videoHolder">
            <div className="video-list">
                {videoData.map((videoItem, index) => (
                    <ServiceItem
                        key={index}
                        videoData={videoItem}
                        playing={index === playingIndex}
                        onPlay={() => handlePlay(index)}
                    />
                ))}
                
            </div>
          </div>
        </div>
      );
}

export default ServiceListComponent;