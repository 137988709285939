import "./home-center-text-components.css";


const HomeCenterTextComponent = (props) => {
    return (
        <div className="home-center-text-component" >
          <h2>{props.title}</h2>
          <p>{props.text}</p>
        </div>
      );
}

export default HomeCenterTextComponent;