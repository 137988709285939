// import HomeHeaderComponent from "../components/home/HomeHeaderComponent";

const WorkPage = () => {
    return (
      <div>
        {/* <HomeHeaderComponent/> */}
      </div>
    );
  };
  
  export default WorkPage;