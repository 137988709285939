import "./service-text-components.css";
import React from 'react';

const ServiceTextComponent = (props) => {
  const flightTitle = "legal flight operations"
  const flightText = "Our team is not only fully licensed but also boasts comprehensive liability insurance coverage. We take compliance seriously, enabling us to conduct fully legal flight operations in almost any location.";

  function replaceWithBr() {
      return flightText.replace(/\n/g, "<br />");
  }
  
  return (
      <div className="service-text-component" >
        <h3>{flightTitle}</h3>
        
        <p dangerouslySetInnerHTML={{ __html: replaceWithBr() }} />
        
      </div>
    );

}

export default ServiceTextComponent;