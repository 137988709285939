import "./home-text-component.css";
import { Link } from 'react-router-dom';

const HomeTextComponent = (props) => {
    return (
        <div className="home-text-component" >
          <h3>FPV Drone services</h3>
          <p>As seasoned and certified drone pilots, we bring a wealth of experience in both flight and production to every project. 
            Our capabilities extend beyond boundaries, allowing us to take to the skies at any location, any time. 
            Discover a spectrum of video production services tailored to your needs, including: 
            <Link to="/service" className="link-style">
                &nbsp;Indoor FPV Tours, Real Estate Tours, Automotive, Action Sports, Boats, Festivals & Events, Weddings, Nature or Collaborate with Other Cinematographers
            </Link>
            <br /><br />If you have any project in mind? 
            <Link to="/contact" className="link-style">
                &nbsp;Please let us know!.
            </Link>
            </p>
        </div>
      );
}

export default HomeTextComponent;