import "./home-welcome-components.css";
import headerVideo from '../../images/promo-web-nocturno.mp4'
import headerVideoMobile from '../../images/promo-web-nocturno-mobile.mp4'
import {isMobile} from 'react-device-detect';

const HomeWelcomeComponent = (props) => {
    let headerTextColor = "#ffffff"

    let videoToShow = isMobile ? headerVideoMobile : headerVideo

    return (
        <div className="home-welcome">
          <div 
            className="welcome-image"
          >
            <div className="video-container">
                <video autoPlay loop muted playsInline>
                    <source src={videoToShow} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="color-overlay"></div>
            </div>
          </div>
          <div className="welcome-header-center">
            <h1 style={{color: headerTextColor}}>{props.title}</h1>
            <p style={{color: headerTextColor}}>{props.desc}</p>
          </div>
    
        </div>
      );
};

export default HomeWelcomeComponent;
