import './service-item.css'
import ReactPlayer from 'react-player';

const ServiceItem = ({ videoData, playing, onPlay }) => {

  return (
    <div className="service-item" >

      <ReactPlayer 
        url={videoData.videoUrl}
        controls={playing}
        width="100%"
        height="100%"
        playing={playing}
        onPlay={onPlay}
      />
    
      <p>{videoData.title}</p>

      {!playing && (
        <div className="play-icon-holder" onClick={onPlay}>
          <div className="overlay"></div>
          <div className='play-icon'>
            <span className='play-icon-svg'>
              <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="33" cy="33" r="31.5" stroke="white" strokeOpacity="0.8" strokeWidth="3"/>
                <path d="M51.75 34.299L24.75 49.8875C23.75 50.4648 22.5 49.7432 22.5 48.5885L22.5 17.4115C22.5 16.2568 23.75 15.5352 24.75 16.1125L51.75 31.701C52.75 32.2783 52.75 33.7217 51.75 34.299Z" stroke="white" strokeOpacity="0.8" strokeWidth="3"/>
              </svg>
            </span>
          </div>
      </div>
        )}
      
    </div>
  );
};

export default ServiceItem;