import HomeCenterTextComponent from "../components/home/HomeCenterTextComponent";
import FooterComponent from "../components/footer/FooterComponent";
import PrivacyPolicyComponent from "../components/privacy/PrivacyPolicyComponent";

import Slide from "@mui/material/Slide";

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}


const PrivacyPage = () => {

    return (
      <div>

        <PrivacyPolicyComponent />
        
        <FooterComponent/>

      </div>
    );
  };
  
  export default PrivacyPage;
  