import "./service-header-text-components.css";
import React from 'react';


const ServiceHeaderTextComponent = (props) => {
  const title = "Service"
  const text = "We understand that creativity knows no bounds. That's why we offer an extensive fleet of cutting-edge FPV drones, each designed to turn your wildest ideas into jaw-dropping reality. We can even make custom drone for your needs.\n\nFrom small indoor to high-speed FPV drones reaching speeds of up to 180 km/h in seconds, we're equipped to capture the extraordinary. Whether it's seamless one-take indoor office or Real Estate tours, tracking fast-moving cars or boats, filming action sports, covering events, or crafting compelling company promos, we're here to turn your boldest concepts into stunning reality.\n\nWe are also open for collaboration with other video production companies. ";

  function replaceWithBr() {
      return text.replace(/\n/g, "<br />");
  }
  
  return (
      <div className="header-text-components" >
        <h1>{title}</h1>
        
        <p dangerouslySetInnerHTML={{ __html: replaceWithBr() }} />
        
      </div>
    );

}

export default ServiceHeaderTextComponent;