import ContactFormComponent from "../components/contact/ContactFormComponent";
import HomeCenterTextComponent from "../components/home/HomeCenterTextComponent";
import FooterComponent from "../components/footer/FooterComponent";

import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const ContactPage = () => {

  const sendRequest = (email, name, message) => {
    if (!validateEmail(email)) {
      setErrorSnackbarOpen(true);
      return;
    }

    if (name.length < 5) {
      setErrorSnackbarOpen(true);
      return;
    }

    if (message.length < 10) {
      setErrorSnackbarOpen(true);
      return;
    }

    sendEmail(email, name, message);
  };

  const handleSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
    setErrorSnackbarOpen(false);
  };

  const [openSuccessSnackbar, setSuccessSnackbarOpen] = useState(false);
  const [openErrorSnackbar, setErrorSnackbarOpen] = useState(false);

  const sendEmail = async (email, name, message) => {
    let text =
      "Nova poruka od: " +
      name +
      ", glasi: " +
      message + 
      ", email:" + email;

    let emailRequest = {
      to: [
        {
          email: "info@nocturno-creative.com",
          name: "Pero Pavlovic",
        },
      ],
      sender: {
        email: email,
        name: name,
      },
      subject: "Nova poruka sa stranice",
      htmlContent: text,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key":
          "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",
      },
      body: JSON.stringify(emailRequest),
    };

    let response = await fetch(
      "https://api.sendinblue.com/v3/smtp/email",
      requestOptions
    );

    let json = await response.json();

    setSuccessSnackbarOpen(true);
  };

    return (
      <div>

        <Snackbar
          message="Thanks, we got your message and will reply soon"
          autoHideDuration={4000}
          open={openSuccessSnackbar}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          TransitionComponent={TransitionRight}
        />
        <Snackbar
          message="Provided information is invalid"
          autoHideDuration={4000}
          open={openErrorSnackbar}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          TransitionComponent={TransitionRight}
        />

        <HomeCenterTextComponent 
        title="Contact us"
        text="Reach us at info@nocturno-creative.com"
        />

        <ContactFormComponent 
          validateAndCheckForm={sendRequest}
        />
          
        <FooterComponent/>

      </div>
    );
  };
  
  export default ContactPage;
  