import "./service-production-text-component.css";
import { Link } from 'react-router-dom';

const HomeTextComponent = (props) => {
    return (
        <div className="service-production-text-component" >
          <h3>OUR PRODUCTION</h3>
          <p>We offer a complete in-house production service. Our process begins with capturing breathtaking shots using FPV drones, followed by meticulous stabilisation using stabilisation software. When it comes to video editing, we are adding both visual and audio effects with DaVinci Resolve Studio. Rest assured, all audio used in our projects is backed by copyright licenses, ensuring a seamless and legal production experience. Your creative vision is our commitment.
            <br /><br />If you have any project in mind? 
            <Link to="/contact" className="link-style">
                &nbsp;Please let us know!.
            </Link>
            </p>
        </div>
      );
}

export default HomeTextComponent;