import "./navbar-component.css";
import hamburgerMenuImage from "../../images/hamburger-menu.svg";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { Link } from 'react-scroll';
import { useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import close from "../../images/close.png";
import mainLogo from "../../images/main-logo.svg";
// import { ga, event } from "../../App";

const NavBarComponent = () => {

  let activeStyleDark = {
    color: "#99C6D4",
    fontFamily: "DescovarAlpha",
    fontWeight: 400,
  };

  let nonActiveStyleDark = {
    color: "#ffffff",
    fontFamily: "DescovarAlpha",
    fontWeight: 400,
  };

  const location = useLocation();

  const [navHeight, setNavHeight] = useState("auto");

  const [hamburgerStyle, setHamburgerStyle] = useState({ display: "none" });

  const onHamburgerMenuClick = () => {
    if (hamburgerStyle.display === "none") {
      setHamburgerStyle({ display: "block" });
      setNavHeight("100vh");
      disableBodyScroll(document);
    } else {
      setHamburgerStyle({ display: "none" });
      setNavHeight("auto");
      enableBodyScroll(document);
    }
  };

  const renderButtonColor = () => {
    // Button
    switch (location.pathname) {
      default:
        return nonActiveStyleDark
    }
  }

  const renderBackgroundImage = () => {
    // Logo 
    switch (location.pathname) {
      default:
        return mainLogo;
    }
  };

  return (
    <div className="navbar" style={{ height: navHeight }}>
      <nav>
        <div className="top">
          <div className="left">
            <NavLink
                to="/"
                >
                <img
                    src={renderBackgroundImage()}
                    alt="Logo of Nocturno Creative"
                />
            </NavLink>
          </div>

          <div className="right">
            <ul>
                <li>
                    <NavLink
                    to="/service"
                    style={({ isActive }) => (isActive ? activeStyleDark : renderButtonColor())}
                    >
                    Service
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink
                    to="/work"
                    style={({ isActive }) => (isActive ? activeStyleDark : renderButtonColor())}
                    >
                    Work
                    </NavLink>
                </li> */}
                {/* <li>
                    <NavLink
                    to="/pricing"
                    style={({ isActive }) => (isActive ? activeStyleDark : renderButtonColor())}
                    >
                    Pricing
                    </NavLink>
                </li> */}
                <li>
                    <NavLink
                    to="/contact"
                    style={({ isActive }) => (isActive ? activeStyleDark : renderButtonColor())}
                    >
                    Contact
                    </NavLink>
                </li>
            </ul>
            <img
              src={hamburgerMenuImage}
              alt="Button to open Nocturno Creative FPV pages menu"
              onClick={onHamburgerMenuClick}
            />
          </div>
        </div>
      </nav>
      <div id="hamburger-menu" style={hamburgerStyle}>
        <img
          src={close}
          alt="Button to close Nocturno Creative FPV pages menu"
          onClick={onHamburgerMenuClick}
        />
        <ul>
          <li>
            <NavLink onClick={onHamburgerMenuClick} to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink onClick={() => {onHamburgerMenuClick();}} to="/service">
              Service
            </NavLink>
          </li>
          {/* <li>
            <NavLink onClick={() => {onHamburgerMenuClick();}} to="/work">
              Work
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink onClick={() => {onHamburgerMenuClick();}} to="/pricing">
              Pricing
            </NavLink>
          </li> */}
          <li>
            <NavLink onClick={() => {onHamburgerMenuClick();}} to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
      
    </div>
  );
};

export default NavBarComponent;
