import ServiceHeaderTextComponent from "../components/service/ServiceHeaderTextComponent";
import ServiceTextComponent from "../components/service/ServiceTextComponent.jsx";
import ServiceProductionTextComponent from "../components/service/ServiceProductionTextComponent.jsx";
import FooterComponent from "../components/footer/FooterComponent";
import ServiceDroneListComponent from "../components/service/ServiceDroneListComponent";
import ServiceListComponent from "../components/service/ServiceListComponent.jsx";

const ServicePage = () => {
    return (
      <div>
        <ServiceHeaderTextComponent />

        <ServiceListComponent />

        <ServiceTextComponent />

        <ServiceDroneListComponent />

        <ServiceProductionTextComponent />

        <FooterComponent/>

      </div>
    );
  };
  
  export default ServicePage;